import { usePhone } from "contexts/PhoneProvider";
import Info from "images/snackbar-info.svg?react";
import { onKeyDown } from "utils/onKeyDown";

type InfoSnackbarProps = {
	message: string;
	fade: string;
	closeSnackbar: () => void;
};

const InfoSnackbar = ({ message, fade, closeSnackbar }: InfoSnackbarProps) => {
	const phone = usePhone().phone;

	const phoneStyle = phone ? "w-full " : "";
	const phoneStyle2 = phone ? "px-2 py-2" : "px-4 py-3";
	const phoneStyle3 = phone ? "whitespace-break-spaces" : "";

	return (
		<div className={`info-snackbar z-50 fixed top-0 max-w-auto whitespace-nowrap ${fade} ${phoneStyle}`} onClick={closeSnackbar} onKeyDown={onKeyDown(closeSnackbar)}>
			<div className={`border-t-4 p-green px-4 py-3 shadow-md ${phoneStyle2}`}>
				<div className={`flex items-center ${phoneStyle3}`}>
					<Info />
					<p>{message}</p>
				</div>
			</div>
		</div>
	);
};

export default InfoSnackbar;
