import { usePhone } from "contexts/PhoneProvider";
import ErrorIcon from "images/snackbar-error.svg?react";
import { onKeyDown } from "utils/onKeyDown";

type ErrorSnackbarProps = {
	message: string;
	fade: string;
	closeSnackbar: () => void;
};

const ErrorSnackbar = ({ message, fade, closeSnackbar }: ErrorSnackbarProps) => {
	const phone = usePhone().phone;

	const phoneStyle = phone ? "w-full " : "";
	const phoneStyle2 = phone ? "px-2 py-2" : "px-4 py-3";
	const phoneStyle3 = phone ? "whitespace-break-spaces" : "";

	return (
		<div className={`error-snackbar z-50 fixed top-0 max-w-auto whitespace-nowrap ${fade} ${phoneStyle}`} onClick={closeSnackbar} onKeyDown={onKeyDown(closeSnackbar)}>
			<div className={`border-t-4 p-green shadow-md ${phoneStyle2}`}>
				<div className={`flex items-center ${phoneStyle3}`}>
					<ErrorIcon />
					<p>{message}</p>
				</div>
			</div>
		</div>
	);
};

export default ErrorSnackbar;
