import FillSurveyModal from "components/fill-survey-modal/FillSurveyModal";
import LanguageDropdown from "components/language-dropdown/LanguageDropdown";
import { useModal } from "contexts/ModalProvider";
import Close from "images/close.svg?react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { store, type RootState } from "redux/store";
import Logout from "images/logout.svg?react";
import { clearAxiosAuthorization } from "utils/axios";
import { removeUser } from "redux/authSlice";
import { useSnackbar } from "contexts/SnackbarProvider";
import Button from "components/buttons/Button";

const NavbarFullscreen = () => {
	const userInfo = useSelector((state: RootState) => state.auth.userInfo);
	const { t } = useTranslation();
	const { showFullscreenModal, closeModal } = useModal();
	const navigate = useNavigate();
	const { openInfoSnackbar } = useSnackbar();

	const handleSurveyClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (!userInfo.id) {
			event.preventDefault();
			showFullscreenModal(<FillSurveyModal />);
		} else closeModal();
	};

	const handleLogout = () => {
		navigate("/");
		store.dispatch(removeUser());
		clearAxiosAuthorization();
		openInfoSnackbar(t("SNACKBAR.LOGOUT"));
	};

	return (
		<nav className="navbar-fullscreen">
			<div className="topbar">
				<LanguageDropdown />
				<Close onClick={() => closeModal()} className="close" />
			</div>
			<ul className="links">
				<li>
					<Link to={"/"} title="Homepage" onClick={() => closeModal()}>
						{t("NAVBAR.HOME")}
					</Link>
				</li>
				<li>
					<Link to={"/topic-select"} onClick={handleSurveyClick} title="Topic select">
						{t("NAVBAR.SURVEY")}
					</Link>
				</li>
			</ul>
			<div className="buttons">
				{!userInfo.id ?
					<>
						<Link to={"/login"} className="button accent medium uppercase" title="login" onClick={() => closeModal()}>
							{t("NAVBAR.LOGIN")}
						</Link>
						<Link to={"/register"} className="button reverse-accent medium uppercase" title="register" onClick={() => closeModal()}>
							{t("LOGIN.REGISTER")}
						</Link>
					</>
				:
					<Button className={"uppercase logout-button"} size="medium" title="logout" onClick={handleLogout}>
						{t("NAVBAR.LOGOUT")}
						<Logout className="logout"/>
					</Button>
				}
			</div>
		</nav>
	);
};

export default NavbarFullscreen;
