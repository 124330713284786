import Button from "components/buttons/Button";
import FillSurveyModal from "components/fill-survey-modal/FillSurveyModal";
import { useModal } from "contexts/ModalProvider";
import { usePhone } from "contexts/PhoneProvider";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import type { RootState } from "redux/store";

const HeaderBlob = () => {
	const navigate = useNavigate();
	const userInfo = useSelector((state: RootState) => state.auth.userInfo);
	const language = useSelector((state: RootState) => state.language.language);
	const { showModal, showFullscreenModal } = useModal();
	const { t } = useTranslation();
	const phone = usePhone().phone;

	const handleSurveyClick = () => {
		if (!userInfo.id) {
			if (phone) showFullscreenModal(<FillSurveyModal />);
			else showModal(<FillSurveyModal />);
		} else {
			navigate("/topic-select");
		}
	};

	return (
		<>
			<section className={`${phone ? "phone-header-blob" : "header-blob"}`}>
				{phone ? (
					<div className="mobile-wrapper">
						<h1 className={`${language === "de" ? "break-all" : ""}`}>{t("HOMEPAGE.INTRO.TITLE")}</h1>
						<p>{t("HOMEPAGE.INTRO.DESCRIPTION")}</p>
						<img src="/images/img-01.webp" alt="header-image" />
						<Button color={"primary"} onClick={handleSurveyClick}>
							{t("HOMEPAGE.INTRO.TAKE_SURVEY")}
						</Button>
					</div>
				) : (
					<div className="wrapper">
						<div className="left-side">
							<div className="text-wrapper">
								<h1>{t("HOMEPAGE.INTRO.TITLE")}</h1>
								<p>{t("HOMEPAGE.INTRO.DESCRIPTION")}</p>
								<Button color={"primary"} onClick={handleSurveyClick}>
									{t("HOMEPAGE.INTRO.TAKE_SURVEY")}
								</Button>
							</div>
						</div>
						<div className="right-side">
							<img src="/images/img-01.webp" alt="header-image" />
						</div>
					</div>
				)}
			</section>
		</>
	);
};

export default HeaderBlob;
