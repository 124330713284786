import { createContext, useContext } from "react";
import useWindowSize from "utils/useWindowSize";

type PhoneContextType = {
	phone: boolean;
};

export const PhoneContext = createContext<PhoneContextType | null>(null);

type PhoneProviderProps = {
	children: React.ReactNode;
};

export const PhoneProvider = ({ children }: PhoneProviderProps) => {
	const [width] = useWindowSize();
	const phone = width < 820;

	const contextValue = { phone };

	return <PhoneContext.Provider value={contextValue}>{children}</PhoneContext.Provider>;
};

export const usePhone = () => {
	const currentContext = useContext(PhoneContext);
	if (!currentContext) {
		throw new Error("usePhone must be used within a PhoneProvider");
	}
	return currentContext;
};
