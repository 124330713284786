import FaArrowUp from 'images/arrow.svg?react';

interface JumpToTopProps {
  dissapear: boolean;
}

const JumpToTop = ({dissapear}: JumpToTopProps) => {

  const handleJump = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <button className={`jump-to-top ${dissapear ? "dissapear" : ""}`} onClick={handleJump} type='button'>
      <FaArrowUp className='rotate-90'/>
    </button>
  );
};

export default JumpToTop;
