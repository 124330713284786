import FillSurveyModal from "components/fill-survey-modal/FillSurveyModal";
import LanguageDropdown from "components/language-dropdown/LanguageDropdown";
import NavUser from "components/nav-user/NavUser";
import NavbarFullscreen from "components/navbar-fullscreen/NavbarFullscreen";
import { useModal } from "contexts/ModalProvider";
import { usePhone } from "contexts/PhoneProvider";
import EULogo from "images/IntegrAGE-EU.svg?react";
import Logo from "images/IntegrAGE-logo.svg?react";
import Hamburger from "images/hamburger.svg?react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { RootState } from "redux/store";

const Navbar = () => {
	const userInfo = useSelector((state: RootState) => state.auth.userInfo);
	const { t } = useTranslation();
	const { showModal, showFullscreenModal } = useModal();
	const phone = usePhone().phone;

	const handleSurveyClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (!userInfo.id) {
			event.preventDefault();
			showModal(<FillSurveyModal />);
		}
	};

	return (
		<nav className="navbar">
			<div className="logo-wrapper">
				<Link to={"/"} title="Homepage">
					{phone ? (
						<div className="full-logo-wrapper">
							<EULogo />
							<Logo style={{ width: "120%", maxHeight: "100%" }} />
						</div>
					) : (
						<Logo />
					)}
				</Link>
			</div>
			{phone ? (
				<div className="links-wrapper">
					{userInfo.id ? (
						<NavUser />
					) : (
						<Link to={"/login"} className="button accent small uppercase" title="login">
							{t("NAVBAR.LOGIN")}
						</Link>
					)}
					<Hamburger onClick={() => showFullscreenModal(<NavbarFullscreen />)} className="cursor-pointer"/>
				</div>
			) : (
				<div className="links-wrapper">
					<ul>
						<li>
							<Link to={"/"} title="Homepage">
								{t("NAVBAR.HOME")}
							</Link>
						</li>
						<li>
							<Link to={"/topic-select"} onClick={handleSurveyClick} title="Topic select">
								{t("NAVBAR.SURVEY")}
							</Link>
						</li>
					</ul>
					<div className="user-wrapper">
						{userInfo.id ? (
							<NavUser />
						) : (
							<Link to={"/login"} className="button accent medium uppercase" title="login">
								{t("NAVBAR.LOGIN")}
							</Link>
						)}
						<LanguageDropdown />
					</div>
				</div>
			)}
		</nav>
	);
};

export default Navbar;
